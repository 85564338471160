import { Plugin } from "@ckeditor/ckeditor5-core";

export default class EmojiActivity extends Plugin {
  /**
   * @inheritDoc
   */
  static get pluginName() {
    return "EmojiActivity";
  }

  /**
   * @inheritDoc
   */
  init() {
    const editor = this.editor;
    const t = editor.t;

    editor.plugins.get("Emoji").addItems("⚽ " + t("Activity"), [
      { title: "Person in Suit Levitating", character: "🕴️" },
      { title: "Person Climbing", character: "🧗" },
      { title: "Man Climbing", character: "🧗‍♂️" },
      { title: "Woman Climbing", character: "🧗‍♀️" },
      { title: "Person Fencing", character: "🤺" },
      { title: "Horse Racing", character: "🏇" },
      { title: "Skier", character: "⛷️" },
      { title: "Snowboarder", character: "🏂" },
      { title: "Person Golfing", character: "🏌️" },
      { title: "Man Golfing", character: "🏌️‍♂️" },
      { title: "Woman Golfing", character: "🏌️‍♀️" },
      { title: "Person Surfing", character: "🏄" },
      { title: "Man Surfing", character: "🏄‍♂️" },
      { title: "Woman Surfing", character: "🏄‍♀️" },
      { title: "Person Rowing Boat", character: "🚣" },
      { title: "Man Rowing Boat", character: "🚣‍♂️" },
      { title: "Woman Rowing Boat", character: "🚣‍♀️" },
      { title: "Person Swimming", character: "🏊" },
      { title: "Man Swimming", character: "🏊‍♂️" },
      { title: "Woman Swimming", character: "🏊‍♀️" },
      { title: "Person Bouncing Ball", character: "⛹️" },
      { title: "Man Bouncing Ball", character: "⛹️‍♂️" },
      { title: "Woman Bouncing Ball", character: "⛹️‍♀️" },
      { title: "Person Lifting Weights", character: "🏋️" },
      { title: "Man Lifting Weights", character: "🏋️‍♂️" },
      { title: "Woman Lifting Weights", character: "🏋️‍♀️" },
      { title: "Person Biking", character: "🚴" },
      { title: "Man Biking", character: "🚴‍♂️" },
      { title: "Woman Biking", character: "🚴‍♀️" },
      { title: "Person Mountain Biking", character: "🚵" },
      { title: "Man Mountain Biking", character: "🚵‍♂️" },
      { title: "Woman Mountain Biking", character: "🚵‍♀️" },
      { title: "Person Cartwheeling", character: "🤸" },
      { title: "Man Cartwheeling", character: "🤸‍♂️" },
      { title: "Woman Cartwheeling", character: "🤸‍♀️" },
      { title: "People Wrestling", character: "🤼" },
      { title: "Men Wrestling", character: "🤼‍♂️" },
      { title: "Women Wrestling", character: "🤼‍♀️" },
      { title: "Person Playing Water Polo", character: "🤽" },
      { title: "Man Playing Water Polo", character: "🤽‍♂️" },
      { title: "Woman Playing Water Polo", character: "🤽‍♀️" },
      { title: "Person Playing Handball", character: "🤾" },
      { title: "Man Playing Handball", character: "🤾‍♂️" },
      { title: "Woman Playing Handball", character: "🤾‍♀️" },
      { title: "Person Juggling", character: "🤹" },
      { title: "Man Juggling", character: "🤹‍♂️" },
      { title: "Woman Juggling", character: "🤹‍♀️" },
      { title: "Person in Lotus Position", character: "🧘" },
      { title: "Man in Lotus Position", character: "🧘‍♂️" },
      { title: "Woman in Lotus Position", character: "🧘‍♀️" },
      { title: "Circus Tent", character: "🎪" },
      { title: "Skateboard", character: "🛹" },
      { title: "Canoe", character: "🛶" },
      { title: "Reminder Ribbon", character: "🎗️" },
      { title: "Admission Tickets", character: "🎟️" },
      { title: "Ticket", character: "🎫" },
      { title: "Military Medal", character: "🎖️" },
      { title: "Trophy", character: "🏆" },
      { title: "Sports Medal", character: "🏅" },
      { title: "1st Place Medal", character: "🥇" },
      { title: "2nd Place Medal", character: "🥈" },
      { title: "3rd Place Medal", character: "🥉" },
      { title: "Soccer Ball", character: "⚽" },
      { title: "Baseball", character: "⚾" },
      { title: "Softball", character: "🥎" },
      { title: "Basketball", character: "🏀" },
      { title: "Volleyball", character: "🏐" },
      { title: "American Football", character: "🏈" },
      { title: "Rugby Football", character: "🏉" },
      { title: "Tennis", character: "🎾" },
      { title: "Flying Disc", character: "🥏" },
      { title: "Bowling", character: "🎳" },
      { title: "Cricket Game", character: "🏏" },
      { title: "Field Hockey", character: "🏑" },
      { title: "Ice Hockey", character: "🏒" },
      { title: "Lacrosse", character: "🥍" },
      { title: "Ping Pong", character: "🏓" },
      { title: "Badminton", character: "🏸" },
      { title: "Boxing Glove", character: "🥊" },
      { title: "Martial Arts Uniform", character: "🥋" },
      { title: "Goal Net", character: "🥅" },
      { title: "Flag in Hole", character: "⛳" },
      { title: "Ice Skate", character: "⛸️" },
      { title: "Fishing Pole", character: "🎣" },
      { title: "Running Shirt", character: "🎽" },
      { title: "Skis", character: "🎿" },
      { title: "Sled", character: "🛷" },
      { title: "Curling Stone", character: "🥌" },
      { title: "Direct Hit", character: "🎯" },
      { title: "Pool 8 Ball", character: "🎱" },
      { title: "Video Game", character: "🎮" },
      { title: "Slot Machine", character: "🎰" },
      { title: "Game Die", character: "🎲" },
      { title: "Puzzle Piece", character: "🧩" },
      { title: "Chess Pawn", character: "♟️" },
      { title: "Performing Arts", character: "🎭" },
      { title: "Artist Palette", character: "🎨" },
      { title: "Thread", character: "🧵" },
      { title: "Yarn", character: "🧶" },
      { title: "Musical Score", character: "🎼" },
      { title: "Microphone", character: "🎤" },
      { title: "Headphone", character: "🎧" },
      { title: "Saxophone", character: "🎷" },
      { title: "Guitar", character: "🎸" },
      { title: "Musical Keyboard", character: "🎹" },
      { title: "Trumpet", character: "🎺" },
      { title: "Violin", character: "🎻" },
      { title: "Drum", character: "🥁" },
      { title: "Clapper Board", character: "🎬" },
      { title: "Bow and Arrow", character: "🏹" },
    ]);
  }
}
